<template>
  <div class="contacts">
    <Page>
      <!-- <template v-slot:breadcrumbs> breadcrumbs </template> -->
      <h1 class="page-title">Контакты</h1>

      <div class="contacts__content">
        <div class="contacts-info">
          <p>ООО “ВИАРТУР”, ОГРН 1200300017389</p>
          <p>
            Россия, г. Улан-Удэ, пр. Автомоблистов 4А/1, ТРЦ Capital Mаll, оф.
            19
          </p>
          <p>+7 950 388 86 68</p>
          <p>info@goguide.ru</p>

          <div v-if="false" class="contacts-info__file">
            <a href="javascript:void(0)" class="file-link">
              Полные реквизиты
            </a>
          </div>
        </div>

        <div class="contacts__right">
          <ContactsForm sourcePage="contacts" />
        </div>
      </div>
    </Page>
  </div>
</template>

<script>
import Page from "@/components/base/Page.vue";
import ContactsForm from "@/components/partials/ContactsForm.vue";

export default {
  name: "Contacts",
  components: { Page, ContactsForm },
};
</script>
